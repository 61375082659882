<template>
  <div class="block">
    <el-page-header @back="dynamic" content="加油记录" style="margin-bottom: 15px;">
    </el-page-header>
    <el-dialog title="新增" :visible.sync="outerVisible">
      <el-form :model="ruleForm" :inline="true"  status-icon ref="ruleForm" label-width="100px" class="demo-form-inline">

        <el-form-item>
          <el-select v-model="ruleForm.cardId" placeholder="请选择卡号" @change="selectCag" style="width: 300px">
            <el-option
                style="width: 300px"
                v-for="item in cardData"
                :key="item.cardId"
                :label="item.cardName"
                :value="item.cardId">
              <span style="float: left">{{ item.cardName }}</span>
              <span style="float: right; color: #8492a6;">{{ item.cardNum }}</span>
            </el-option>
          </el-select>
          <span style="color: red; padding-left: 12px"> 余额： </span>
          <el-tag type="success">{{balance }}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-select v-model="ruleForm.carId" placeholder="请选择车型" style="width: 180px">
            <el-option
                style="width: 170px"
                v-for="item in carData"
                :key="item.carId"
                :label="item.carNum"
                :value="item.carId">
              <span style="float: left">{{ item.carNum }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.carName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="ruleForm.empId" placeholder="请选择使用人" style="width: 120px">
            <el-option
                style="width: 100px"
                v-for="item in empData"
                :key="item.empId"
                :label="item.empName"
                :value="item.empId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="block">
            <el-date-picker
                style="width: 140px"
                v-model="ruleForm.oilDate"
                align="right"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-input v-model="ruleForm.oil" style="width: 70px" placeholder="数量"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="ruleForm.price" style="width: 60px" placeholder="单价"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="ruleForm.balance" style="width: 80px" placeholder="金额"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="ruleForm.total" style="width: 100px" placeholder="余额"></el-input>
        </el-form-item>
      </el-form>
      <el-dialog
          width="20%"
          title="警告"
          :visible.sync="innerVisible"
          append-to-body>
        <span>您将新增一条记录，请确认！</span>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="resetForm();innerVisible = false;outerVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submitForm('ruleForm');innerVisible = false; outerVisible = false">确 定</el-button>
          </span>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="resetForm();outerVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="innerVisible = true">提交</el-button>
      </div>
    </el-dialog>

    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-button type="info" @click="resetForm();outerVisible = true" style="margin-right: 10px">新增</el-button>
      <el-form-item label="日期">
        <el-date-picker
            style="width: 260px"
            v-model="formInline.oilDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="车型" style="padding-left: 8px">
        <el-select v-model="formInline.CarNum" clearable placeholder="请选择" style="width: 170px">
          <el-option
              v-for="item in carNum"
              :key="item.value"
              :label="item.carNum"
              :value="item.carId">
            <span style="float: left">{{ item.carNum }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.carName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="卡号">
        <el-select v-model="formInline.CardNum" clearable placeholder="请选择">
          <el-option
              v-for="item in cardData"
              :key="item.value"
              :label="item.cardName"
              :value="item.cardId">
            <span style="float: left">{{ item.cardName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cardNum }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit(formInline)">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="oilData"
        show-summary
        style="width: 100%; margin: auto"
        :cell-style="{padding: 0}">
      <el-table-column
          fixed
          prop="index"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="car.carNum"
          label="车牌">
      </el-table-column>
      <el-table-column
          prop="oil"
          label="加油量"
          width="80">
      </el-table-column>
      <el-table-column
          prop="price"
          label="单价"
          width="80">
      </el-table-column>
      <el-table-column
          prop="total"
          label="金额"
          width="80">
      </el-table-column>
      <el-table-column
          prop="card.cardNum"
          label="卡号"
          width="200">
      </el-table-column>
      <el-table-column
          prop="balance"
          label="余额"
          width="100">
      </el-table-column>
      <el-table-column
          prop="emp.empName"
          label="使用人"
          width="100">
      </el-table-column>
      <el-table-column
          prop="oilDate"
          label="日期"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button

              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "oil",
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      ruleForm: {
        carId: '',
        cardId: '',
        empId: '',
        oilDate: '',
        oil: '',
        price: '',
        total: '',
        balance: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      formInline: {
        oilDate: '',
        CarNum: '',
        CardNum: '',
      },
      carData: [],
      empData: [],
      oilData: [],
      carNum: [],
      cardData: [],
      balance: '0.00'
    }
  },
  mounted() {
    this.getOilList()
    this.getCarNum()
    this.getCardData()
    this.getCarData()
    this.getEmpData()
  },
  methods: {
    onSubmit(data) {
      var reqUrl
      if (data.length == "" || data == null) {
        reqUrl = '/api/oil/getOilList/'
      }
      if (data.oilDate == null || data.oilDate.length == "") {
        if (data.CarNum.length == "") {
          if (data.CardNum.length == "") {
            reqUrl = '/api/oil/getOilList/'
          } else reqUrl = '/api/oil/getOilList/' + 0 + '/' + data.CardNum + '/' + 0 + '/' + 0
        } else if (data.CardNum.length == "") {
          reqUrl = '/api/oil/getOilList/' + data.CarNum + '/' + 0 + '/' + 0 + '/' + 0
        } else reqUrl = '/api/oil/getOilList/' + data.CarNum + '/' + data.CardNum + '/' + 0 + '/' + 0
      } else {
        if (data.CarNum.length == "") {
          if (data.CardNum.length == "") {
            reqUrl = '/api/oil/getOilList/' + 0 + '/' + 0 + '/' + data.oilDate[0] + '/' + data.oilDate[1]
          } else reqUrl = '/api/oil/getOilList/' + 0 + '/' + data.CardNum + '/' + data.oilDate[0] + '/' + data.oilDate[1]
        } else if (data.CardNum.length == "") {
          reqUrl = '/api/oil/getOilList/' + data.CarNum + '/' + 0 + '/' + data.oilDate[0] + '/' + data.oilDate[1]
        } else reqUrl = '/api/oil/getOilList/' + data.CarNum + '/' + data.CardNum + '/' + data.oilDate[0] + '/' + data.oilDate[1]
      }

      this.$http({
        method: 'get',
        url: reqUrl,
        data: ''
      }).then((res)=>{
        this.oilData = res.data.data
      })
    },
    getOilList() {
      this.$http({
        method: 'get',
        url:'/api/oil/getOilList',
        data:{}
      }).then((res)=>{
        this.oilData = res.data.data
      })
    },
    getCarNum() {
      this.$http({
        method: 'get',
        url: '/api/car/getCarList',
        data: []
      }).then((res)=>{
        this.carNum = res.data.data
      })
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    getCardData() {
      this.$http({
        method: 'get',
        url: '/api/card/getCardList',
        data: []
      }).then((res)=>{
        this.cardData = res.data.data
      })
    },
    getCarData() {
      this.$http({
        method: 'get',
        url: '/api/car/getCarList',
        data: []
      }).then((res)=>{
        this.carData = res.data.data
      })
    },
    getEmpData() {
      this.$http({
        method: 'get',
        url: '/api/emp/getEmpList',
        data: []
      }).then((res)=>{
        this.empData = res.data.data
      })
    },
    selectCag(cardId) {
      this.balance = '000.00'
      this.balance = this.cardData[this.cardData.findIndex((card) => card.cardId == cardId)].balance
    },
    resetForm() {
      this.ruleForm = {}
      this.balance = '000.00'
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            method: 'post',
            url: '/api/oil/insertOil',
            data: this.ruleForm
          }).then((res)=>{
            alert(res.data.msg)
            if (res.data.status) this.ruleForm = {}
            this.getOilList()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.el-table {
  overflow: visible !important;
}
</style>